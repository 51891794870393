import React from 'react'
import './NavItem.scss'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
//import { makeStyles } from '@material-ui/core/styles';
//import { useHistory } from "react-router-dom";

// const useStyles = makeStyles((theme) => ({
//   categoryButton: {

//   },
// }))
interface Nav {
  text: string
  to: string
  variant: any
  scrollTo: any
}

const NavItem: React.FC<Nav> = (props) => {
  //const classes = useStyles();

  //let history = useHistory();

  function handleClick(to: string) {
    //history.push(to);

    props.scrollTo(to)
    console.log('[NavItem]', to)
  }
  return (
    <Button color="inherit" onClick={() => handleClick(props.to)} className="NavItem">
      <Typography variant={props.variant} noWrap style={{ marginLeft: '7px', marginRight: '7px', paddingRight: '3px' }} className="NavItemText">
        {props.text}
      </Typography>
    </Button>
  )
}

export default NavItem
