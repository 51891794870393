import React from 'react'
import Grid from '@material-ui/core/Grid'

import './News.scss'

//import CarlBangz from '../../assets/images/events/CarlBangz.jpg'
//import CoctailNight from '../../assets/images/events/CoctailNight.jpg'
//import NDM from '../../assets/images/events/NDM.jpg'
//import DennisBackpiano from '../../assets/images/events/DennisBackpiano.jpg'
//import DJ from '../../assets/images/events/DJ.jpg'
//import RonTone from '../../assets/images/events/RonTone.jpg'
//import Saxophonist from '../../assets/images/events/Saxophonist.jpg'
// import Salitos from '../../assets/images/events/Salitos.jpg'
// import Concorde from '../../assets/images/events/Concorde.jpg'
// import HappyHour from '../../assets/images/events/HappyHour2.jpg'
// import Closing from '../../assets/images/events/Closing.jpg'
// import ReneReuter from '../../assets/images/events/ReneReuter.png'

const News: React.FC = () => {
  return (
    <div>
      <h1>News</h1>
      <h3>Neues rund um den Stadstrand am Hengsteysee</h3>
      <p>Neues rund um den Stadstrand am Hengsteysee erf&auml;hrst Du hier, oder aber &uuml;ber unserer</p>
      <p>
        Socialmedia-Kan&auml;le auf{' '}
        <a href="https://instagram.com/stadtstrand_hagen" target="_blank" rel="noopener noreferrer">
          Instagram
        </a>{' '}
        und{' '}
        <a href="https://facebook.com/stadtstrand.hagen" target="_blank" rel="noopener noreferrer">
          Facebook
        </a>
        !
      </p>

      <Grid container spacing={3} className="event-container">
        <Grid item xs={12} md={2} className="events">
          <h3>Events</h3>
        </Grid>
        <Grid item xs={12} md={10} className="events">
        
          <Grid container spacing={3} className="events-row">
            <Grid item xs={12} md={2}>
            <h4>+++ SAISONENDE +++</h4>
            </Grid>
            <Grid item xs={12} md={8}>
              
              <p>Der Stadtstrand Hagen ist für dieses Jahr leider geschlossen! <br />

Wir danken Euch für eine tolle Zeit und freuen uns auf ein Wiedersehen auf dem Hagener Weihnachtsmarkt!</p>
            </Grid>
          </Grid>
          
        </Grid>
        <Grid item xs={12} md={2}>
          <h3>Corona-Infos</h3>
        </Grid>
        <Grid item xs={12} md={10}>
          <p>
            <h3>ACHTUNG:</h3>
            <p>
              Bitte installieren Sie bereits vor Ihrem Besuch die "Luca-App". <br />
              Damit checken Sie sicher bei uns ein und helfen uns dabei, im Ernstfall Infektionsketten nachverfolgen zu können.
            </p>
          </p>
          <p>Aktuelle Regelungen bei Inzidenzstufe 1</p>
          <p>Die Bedingungen für den Einlass sind aufgrund der aktuellen Corona-Infektionslage wie folgt:</p>
          <ul>
            <li>
              <p>Für den Einlass ist kein negativer Corona-Test mehr erforderlich!</p>
            </li>

            <li>
              <p>Pro Tisch sind max. 5 Haushalte ohne Begrenzung der Personenanzahl gestattet.</p>
            </li>

            <li>
              <p>Alternativ sind bis zu 100 Personen ohne Begrenzung der Haushalte mit negativem Test (max. 48 Std. alt), zzgl. vollständig geimpfte und genesene Personen erlaubt!</p>
            </li>
          </ul>
          <p>Im gesamten Bereich des Stadtstrandes am Hengsteysee gelten zudem folgende Maßnahmen:</p>
          <ul>
            <li>Mund/Nasenschutzpflicht (außer am Tisch)</li>
            <li>1,5 m Abstandsregelung</li>
            <li>Regelmäßige Handdesinfektion</li>
          </ul>
          Derzeit können wir Ihnen nur feste Sitzplätze anbieten.
        </Grid>
      </Grid>
    </div>
  )
}

export default News
