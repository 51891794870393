import React from 'react'
import IframeResizer from 'iframe-resizer-react'

const Reservierung: React.FC = () => {
  
  return (
    <div>
      <h1>Reservierung</h1>
      <h3>Reservier Dir ein Plätzchen.</h3>
      <IframeResizer log
   src="https://stadtstrand.butter.place/reservations/make/0c4d0fb4-9fdf-47d2-872b-6013a01b3bf4" style={{ width: '1px', minWidth: '100%', border: '0px'}} />
    </div>
  )
}

export default Reservierung
