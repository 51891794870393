import React from 'react'
import Grid from '@material-ui/core/Grid'
import './Infos.scss'
import GoogleMapReact from 'google-map-react'
import myLogo from '../../assets/images/logo.png'
const AnyReactComponent = (props: any) => (
  <div>
    <img src={myLogo} alt="Logo" style={{ height: '80px' }} />{' '}
  </div>
)

const TextComponent = (props: any) => (
  <div>
    {props.text}
  </div>
)

const Infos: React.FC = (props) => {
  return (
    <div>
      <h1>Infos</h1>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <h3>So findet Ihr zu uns</h3>
          <p>
            Stadtstrand am Hengsteysee
            <br />
            Seestraße 2A
            <br />
            58089 Hagen
          </p>
        </Grid>
        <Grid item xs={12} md={4}>
          <h3>Anreise mit dem ÖPNV</h3>
          <p>Bushaltestelle „Familienbad Hengstey“, Linie 515</p>
        </Grid>
        <Grid item xs={12} md={4}>
          <h3>Parkmöglichkeiten</h3>
          <p>Parkplatz bei „Familienbad Hengstey“</p>
        </Grid>
        <Grid item xs={12}>
          <div className="Maps">
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyDyUhAjQJh1Vf4zkQ5VF8FxGgHWCswGrPs' }}
              defaultCenter={{
                lat: 51.40537157601012, 
                lng: 7.456998596086071,
              }}
              defaultZoom={16}
            >
              <AnyReactComponent lat={51.407390344117886} lng={7.457141401114088} text="Stadtstrand" />
              <TextComponent lat={51.40338035010054} lng={7.455072029175741} text="Parkplatz Familienbad Hengstey"/>
            </GoogleMapReact>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <h3>Öffnungszeiten</h3>
          <h4>+++ SAISONENDE +++</h4>
          <p>
         
Der Stadtstrand Hagen ist für dieses Jahr leider geschlossen!<br />

Wir danken Euch für eine tolle Zeit und freuen uns auf ein Wiedersehen auf dem Hagener Weihnachtsmarkt!
          </p>
          
        </Grid>
        <Grid item xs={12} md={6}>
          <h3>Privat- und Firmenfeiern</h3>
          <p>
            Natürlich steht der Stadtstrand am Hengsteysee auch für Ihre Privat- und Firmenfeier zur Verfügung.
            <br />
            Gerne reservieren wir Ihnen einen speziell abgetrennten Bereich für Ihre Feier! <br />
            Außerdem stellen wir Ihnen gerne auch speziell nach Ihren Vorstellungen ein individuelles Paket zusammen!
            <br />
            Bitte sprechen Sie uns an.
          </p>
        </Grid>
      </Grid>
    </div>
  )
}

export default Infos
