import React from 'react';
import './Logo.scss';
import myLogo from '../../assets/images/logo.png'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 40,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      height: 100,
      margin: 10
      
    },
  },

  logoImage: {
    height: '100%',
  },

}))

const Logo: React.FC<{onClick: any}> = props => {
  const classes = useStyles();
  return(
  <div className={classes.logo}>
    <a href="#home"><img className={classes.logoImage} src={myLogo} alt="StadtStrandLogo" onClick={props.onClick}/></a>
  </div>
)
};

export default Logo;
