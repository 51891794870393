import React from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import './Partner.scss'

import AutohausKramer from '../../assets/images/partner/auto-kramer.jpg'
import KoenigPilsener from '../../assets/images/partner/koenig-pilsener.jpg'
import Kaufland from '../../assets/images/partner/kaufland.png'
import Stoecker from '../../assets/images/partner/stoecker.png'
import HoBa from '../../assets/images/partner/hoba.jpg'
import Steinhoff from '../../assets/images/partner/steinhoff.jpg'
import CocaCola from '../../assets/images/partner/coca-cola.jpg'
import Enervie from '../../assets/images/partner/enervie.jpg'
import MarkE from '../../assets/images/partner/mark-e.jpg'
import RadioHagen from '../../assets/images/partner/radio-hagen.jpg'
import KlEvent from '../../assets/images/partner/kl-eventservice.jpg'

const Partner: React.FC = () => {
  return (
    <div>
      <h1>Partner</h1>
      <h3>Unsere Partner</h3>
      <br/>
      
      <Grid container spacing={10}>
        <Grid item xs={12} md={4}>
          <Paper className="PartnerPaper">
          <img src={AutohausKramer} alt="Autohaus Kramer" className="PartnerLogo"/>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className="PartnerPaper">
          <img src={KoenigPilsener} alt="König Pilsener" className="PartnerLogo"/>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className="PartnerPaper">
          <img src={Kaufland} alt="Kaufland" className="PartnerLogo"/>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className="PartnerPaper">
          <img src={Stoecker} alt="Stöcker Elektroanlagen" className="PartnerLogo"/>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className="PartnerPaper">
          <img src={HoBa} alt="Hofnagel+Bade" className="PartnerLogo"/>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className="PartnerPaper">
          <img src={Steinhoff} alt="Steinhoff" className="PartnerLogo"/>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className="PartnerPaper">
          <img src={CocaCola} alt="Coca Cola" className="PartnerLogo"/>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className="PartnerPaper">
          <img src={Enervie} alt="Enervie" className="PartnerLogo"/>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className="PartnerPaper">
          <img src={MarkE} alt="MarkE" className="PartnerLogo"/>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className="PartnerPaper">
          <img src={RadioHagen} alt="Radio Hagen" className="PartnerLogo"/>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className="PartnerPaper">
          <img src={KlEvent} alt="KL Eventservice" className="PartnerLogo"/>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default Partner
