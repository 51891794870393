import React from 'react'
import Grid from '@material-ui/core/Grid'
import './FoodDrinks.scss'

import Cocktailkarte from '../../assets/images/karten/cocktailkarte.jpg';
import Getraenkekarte from '../../assets/images/karten/getraenkekarte.jpg'
import Speisekarte from '../../assets/images/karten/speisekarte.jpg'

const FoodDrinks: React.FC = () => {
  return (
    <div>
      <h1>Food &amp; Drinks</h1>
      <h3>Beach Food and Drinks !</h3>
      <p>Wir bieten Euch eine reichhaltige Auswahl an K&ouml;stlichkeiten aller Art.</p>
      <Grid container spacing={10}  justify="center">
        <Grid item xs={12} md={6}>
         
          <img src={Cocktailkarte} alt="Cocktailkarte" className="Karte"/>
          
        </Grid>
        <Grid item xs={12} md={6}>
         
         <img src={Getraenkekarte} alt="Getränkekarte" className="Karte" />
         
       </Grid>
       <Grid item xs={12}>
         <div style={{width: '100%', textAlign: 'center'}}>
         <img src={Speisekarte} alt="Speisekarte" className="Karte" />
         </div>
       </Grid>
      </Grid>
    </div>
  )
}

export default FoodDrinks
