import React from 'react';
import './Card.scss';

// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//   card: {
//     height: 600,
//     marginRight: theme.spacing(2),
//     [theme.breakpoints.up('sm')]: {
//       height: 100,
      
//     },
//   },

// }))

const Card: React.FC = props => {
  //const classes = useStyles();
  return(
  <div className="Card">
    {props.children}
  </div>
)
};

export default Card;
