import React, { useRef } from 'react'
import { Parallax, ParallaxLayer, IParallax } from '@react-spring/parallax'
import SectionCard from './components/Card/Card'

import Logo from './assets/logo.png'
import Cocktail from './assets/cocktail.png'
import CoconutTree from './assets/coconut-tree.png'
import LemonMintTea from './assets/lemon-mint-tea.png'

import AutohausKramer from './assets/images/partner/auto-kramer.jpg'
import KoenigPilsener from './assets/images/partner/koenig-pilsener.jpg'
import MarkE from './assets/images/partner/mark-e.jpg'
import Kaufland from './assets/images/partner/kaufland.png'

import UeberUns from './sections/UeberUns/UberUns'
import News from './sections/News/News'
import FoodDrinks from './sections/FoodDrinks/FoodDrinks'
import Reservierung from './sections/Reservierung/Reservierung'
import Impressionen from './sections/Impressionen/Impressionen'
import Partner from './sections/Partner/Partner'
import Infos from './sections/Infos/Infos'
import Kontakt from './sections/Kontakt/Kontakt'
import Impressum from './sections/Impressum/Impressum'

import './App.scss'

import Navbar from './components/Navigation/Navbar/Navbar'

function App() {
  const parallax = useRef<IParallax>(null!)

  return (
    <div style={{ width: '100%', height: '100%', backgroundColor: '#001133' }} className="Background">
      <Parallax ref={parallax} pages={21}>
        {/* Under content decoration section */}

        <ParallaxLayer offset={0.7} speed={-0.3} style={{ pointerEvents: 'none' }}>
          <img src={Cocktail} style={{ width: '30%', minWidth: '400px', marginLeft: '10%' }} alt="Cocktail" />
        </ParallaxLayer>

        <ParallaxLayer offset={0.7} speed={-0.6} style={{ pointerEvents: 'none' }}>
          <img src={LemonMintTea} style={{ width: '20%', minWidth: '400px', marginLeft: '75%' }} alt="Lemon Mint Tea" />
        </ParallaxLayer>

        <ParallaxLayer offset={2.7} speed={0.7} style={{ pointerEvents: 'none' }}>
          <img src={LemonMintTea} style={{ width: '30%', minWidth: '500px', marginLeft: '1%' }} alt="Lemon Mint Tea" />
        </ParallaxLayer>

        <ParallaxLayer offset={4.7} speed={-0.3} style={{ pointerEvents: 'none' }}>
          <img src={Cocktail} style={{ width: '40%', minWidth: '400px', marginLeft: '50%' }} alt="Cocktail" />
        </ParallaxLayer>

        {/* coconut section */}

        <ParallaxLayer offset={0} speed={0.5} style={{ pointerEvents: 'none' }}>
          <img src={CoconutTree} style={{ width: '100%', marginLeft: '-65%' }} alt="Coconut Tree" />
        </ParallaxLayer>

        <ParallaxLayer offset={0} speed={0.2} style={{ pointerEvents: 'none' }}>
          <img src={CoconutTree} style={{ width: '100%', marginLeft: '70%' }} alt="Coconut Tree" />
        </ParallaxLayer>

        <ParallaxLayer offset={1} speed={0.5} style={{ pointerEvents: 'none' }}>
          <img src={CoconutTree} style={{ width: '100%', marginLeft: '-65%' }} alt="Coconut Tree" />
        </ParallaxLayer>

        <ParallaxLayer offset={2} speed={0.2} style={{ pointerEvents: 'none' }}>
          <img src={CoconutTree} style={{ width: '100%', marginLeft: '70%' }} alt="Coconut Tree" />
        </ParallaxLayer>

        <ParallaxLayer offset={3} speed={0.5} style={{ pointerEvents: 'none' }}>
          <img src={CoconutTree} style={{ width: '100%', marginLeft: '-65%' }} alt="Coconut Tree" />
        </ParallaxLayer>

        <ParallaxLayer offset={4} speed={0.2} style={{ pointerEvents: 'none' }}>
          <img src={CoconutTree} style={{ width: '100%', marginLeft: '70%' }} alt="Coconut Tree" />
        </ParallaxLayer>

        <ParallaxLayer offset={5} speed={0.5} style={{ pointerEvents: 'none' }}>
          <img src={CoconutTree} style={{ width: '100%', marginLeft: '-65%' }} alt="Coconut Tree" />
        </ParallaxLayer>

        <ParallaxLayer offset={6} speed={0.2} style={{ pointerEvents: 'none' }}>
          <img src={CoconutTree} style={{ width: '100%', marginLeft: '70%' }} alt="Coconut Tree" />
        </ParallaxLayer>

        {/* 1 Section */}

        <ParallaxLayer
          offset={0}
          speed={0.1}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <img src={Logo} style={{ marginTop: '15vw', width: '45%', maxWidth: '1024px' }} alt="Logo" />
          <div style={{ display: 'flex', width: '20%', justifyContent: 'center', alignItems: 'center' }}>
            <img src={AutohausKramer} style={{ width: '40%', margin: '1rem' }} alt="Autohaus Kramer" />
            <img src={KoenigPilsener} style={{ width: '40%', margin: '1rem' }} alt="König Pilsner" />
            <img src={MarkE} style={{ width: '40%', margin: '1rem' }} alt="MarkE" />
            <img src={Kaufland} style={{ width: '40%', margin: '1rem' }} alt="Kaufland" />
            
          </div>
        </ParallaxLayer>

        {/* 2 Section */}

        <ParallaxLayer
          className="SectionBackground"
          offset={1.1}
          speed={1.2}
          factor={1.4}
          style={{
            height: '3% !important',
            backgroundColor: '#ffcc2255',
          }}
        >
          <SectionCard>
            <UeberUns />
          </SectionCard>
        </ParallaxLayer>

        {/* 3 Section */}
        <ParallaxLayer
          className="SectionBackground"
          offset={3.1}
          speed={1.2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#87BCDEaa',
          }}
        >
          <SectionCard>
            <News />
          </SectionCard>
        </ParallaxLayer>

        {/* 4 Section */}
        <ParallaxLayer
          className="SectionBackground"
          offset={5.1}
          speed={1.2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#ff5500aa',
          }}
        >
          <SectionCard>
            <FoodDrinks />
          </SectionCard>
        </ParallaxLayer>

        {/* 5 Section */}
        <ParallaxLayer
          className="SectionBackground"
          offset={7.1}
          speed={1.2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#ffcc55aa',
          }}
        >
          <SectionCard>
            <Reservierung />
          </SectionCard>
        </ParallaxLayer>

        {/* 6 Section */}
        <ParallaxLayer
          className="SectionBackground"
          offset={9.1}
          speed={1.2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#ffcc99aa',
          }}
        >
          <SectionCard>
            <Impressionen />
          </SectionCard>
        </ParallaxLayer>

        {/* 7 Section */}
        <ParallaxLayer
          className="SectionBackground"
          offset={11.1}
          speed={1.2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#ffcc99aa',
          }}
        >
          <SectionCard>
            <Partner />
          </SectionCard>
        </ParallaxLayer>

        {/* 8 Section */}
        <ParallaxLayer
          className="SectionBackground"
          offset={13.1}
          speed={1.2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#ffcc99aa',
          }}
        >
          <SectionCard>
            <Infos />
          </SectionCard>
        </ParallaxLayer>

        {/* 9 Section */}
        <ParallaxLayer
          className="SectionBackground"
          offset={15.1}
          speed={1.2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#ffcc99aa',
          }}
        >
          <SectionCard>
            <Kontakt />
          </SectionCard>
        </ParallaxLayer>

        {/* 10 Section */}
        <ParallaxLayer
          className="SectionBackground"
          offset={17.1}
          speed={1.2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#ffcc99aa',
          }}
        >
          <SectionCard>
            <Impressum />
          </SectionCard>
        </ParallaxLayer>

        {/* In front decoration section */}


        {/* Navbar Section */}

        <ParallaxLayer offset={0} speed={-1} style={{ backgroundColor: '#fed690', height: '3000px', top: '-2980px' }} className="OnTop" />

        <ParallaxLayer offset={0} speed={-1} style={{ backgroundColor: '#ff00', height: '55px', top: '0px' }}>
          <Navbar
            scrollTo={(to: number) => {
              parallax.current.scrollTo(to)
            }}
          />
        </ParallaxLayer>
      </Parallax>
    </div>
  )
}

export default App
