import React from 'react'
import Gallery from 'react-photo-gallery'
 

const Impressionen: React.FC = () => {
  const photos = [
  
    {
      src: './assets/Gallery/192994418_116687193946606_230137593199852503_n.jpg',
      width: 40,
      height: 30
    },
    {
      src: './assets/Gallery/193150301_116687183946607_1564731213982073854_n.jpg',
      width: 4,
      height: 3
    },
    {
      src: './assets/Gallery/193624079_115641690717823_3794006216012512259_n.jpg',
      width: 4,
      height: 3
    },
    {
      src: './assets/Gallery/194360362_116687197279939_4935014380362872185_n.jpg',
      width: 4,
      height: 3
    },
    {
      src: './assets/Gallery/194409814_115641677384491_7218206902432626154_n.jpg',
      width: 40,
      height: 30
    },
    {
      src: './assets/Gallery/194630037_115530647395594_4058961128628186660_n.jpg',
      width: 4,
      height: 3
    },
    {
      src: './assets/Gallery/195139362_10157666023205946_5909714902445880632_n.jpg',
      width: 4,
      height: 3
    },
    {
      src: './assets/Gallery/195698378_116953567253302_7476612812246764380_n.jpg',
      width: 4,
      height: 3
    },
    {
      src: './assets/Gallery/195881751_116953577253301_8937546927627273680_n.jpg',
      width: 4,
      height: 3
    },
    {
      src: './assets/Gallery/196352924_116953563919969_1399268455883281617_n.jpg',
      width: 4,
      height: 3
    },
    {
      src: './assets/Gallery/197473993_116953573919968_1854238582526728718_n.jpg',
      width: 4,
      height: 3
    }
  ];
  return (
    <div>
      <h1>Impressionen</h1>
      <h3>Sun and Beach !</h3>
      <div style={{maxWidth: '1500px'}}>
      <Gallery photos={photos}/>
      </div>
    </div>
  )
}

export default Impressionen