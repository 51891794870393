import React from 'react'
import Grid from '@material-ui/core/Grid';

import ImageOne from '../../assets/images/ueber-uns/39501487_s.jpg'
import ImageTwo from '../../assets/images/ueber-uns/AdobeStock_181465209.jpeg'
const UberUns: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} >
        <h1>&Uuml;ber uns</h1>
        <h3>Stadtstrand am Hengsteysee- Welcome to the Beach!</h3>
        <p>
          Sonne, Strand, Musik, Palmen und Biergarten-Flair - der Stadtstrand am Hengsteysee ist Dein

          Treffpunkt zum chillen und entspannen in Hagen! 
          </p><p>
          
          Auf dem Parkplatz an der DLRG-Station in der

          Seestra&szlig;e entsteht auf &uuml;ber 600 m2 eine moderne Pop-up-Beachbar, die keine W&uuml;nsche offen l&auml;sst!

          </p><p>
   
          Seeblick, Palmen, coole Drinks, leckeres Essen und nat&uuml;rlich Sand zwischen den Zehen sorgen f&uuml;r

          echtes Urlaubsfeeling.
          </p><p>
          
          Erlebe entspannte Stunden in der neuen, einzigartigen Wohlf&uuml;hl-Oase am

          Hengsteysee und sp&uuml;re den Sommer direkt vor Deiner Haust&uuml;r!
   
        </p>
      </Grid>
      <Grid item xs={12} md={6}><img src={ImageOne} width="100%" alt="Happy people"/></Grid>
      
      <Grid item xs={12} md={6}>
        <h3>Unsere Geschichte</h3>
        <p>
          Schon seit &uuml;ber 50 Jahren ist unser Familienunternehmen im Gastronomie-Bereich auf bekannten
        
          Gro&szlig;veranstaltungen aller Art zuhause. Wir sind Veranstalter und Gastronomen aus Passion. Gastro
     
          und Events sind unsere Leidenschaft. Unsere Berufung ist es seit jeher, Menschen Freude zu
  
          bereiten.
          </p>
          <p>
          Mit dem Stadtstrand am Hengsteysee m&ouml;chten wir nicht nur einen Ort kreieren, an dem die
          
          Menschen Freude und Spa&szlig; empfinden k&ouml;nnen. Der Stadtstrand am Hengsteysee soll seine G&auml;ste die
       
          Sorgen des Alltags f&uuml;r einpaar Stunden komplett vergessen lassen und f&uuml;r echtes Urlaubsfeeling in
       
          der Heimat sorgen.
          </p>

         <p>
          Wir freuen uns nun, f&uuml;r Hagen eine hochwertige Eventgastronomie geschaffen zu haben &ndash; mit
          
          einzigartigem Ambiente, bestem Service und hochwertigen Speisen und Getr&auml;nken.
         
          Was w&uuml;nscht man sich im Sommer mehr?
         
        </p>
      </Grid>
      <Grid item xs={12} md={6}><img src={ImageTwo} width="100%" alt="People drink cocktails"/></Grid>
      
      </Grid>
  )
}

export default UberUns
