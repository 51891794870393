import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';

import ListItemText from '@material-ui/core/ListItemText';


import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },

    menuButton: {
        marginRight: theme.spacing(2),
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

type Anchor = 'top' | 'left' | 'bottom' | 'right';


interface IDrawer {
    scrollTo: any
}


const TemporaryDrawer: React.FC<IDrawer> = (props: IDrawer) => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };


    const list = (anchor: Anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {[{text:'Über Uns', to:1}, {text:'News', to:3}, {text:'Food & Drinks', to:5}, {text:'Reservierung', to:7}, {text:'Impressionen', to:9}, {text:'Partner', to:11}, {text:'Infos', to:13}].map((item, index) => (
                    <ListItem button key={item.text} onClick={() => props.scrollTo(item.to)}>
                        
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {[{text:'Kontakt', to:15}, {text:'Impressum', to:17}, {text:'Datenschutz', to:17}].map((item, index) => (
                    <ListItem button key={item.text} onClick={() => {props.scrollTo(item.to)}}>
                        
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
        </div>
    );
    const anchor = 'left'
    return (
        <div>
            <React.Fragment key={anchor}>
                <IconButton
                    onClick={toggleDrawer(anchor, true)}
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="open drawer"
                    
                >
                    <MenuIcon />
                </IconButton>
                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                    {list(anchor)}
                </Drawer>
            </React.Fragment>
        </div>
    );
}

export default TemporaryDrawer