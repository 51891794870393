import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';

import Logo from '../../Logo/Logo'

import NavItem from '../NavItem/NavItem'
import SideDrawer from '../SideDrawer/SideDrawer'

import './Navbar.scss';



const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: '#ffcc00',   
  },


  categories: {
    
    display: 'none',
    width: '80%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },

  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  social: {
    margin: '10px'
  },
}));

interface Nav {
  scrollTo: (to: number) => void
}

const Navbar: React.FC<Nav> = (props: Nav) => {

  const classes = useStyles();

  return (
    <div className={classes.grow}>
      
      <AppBar  className="MuiAppBar">
        <Toolbar>
          <SideDrawer  scrollTo={(to: any) => props.scrollTo(to)}/>

          <Logo onClick={() => props.scrollTo(0)}/>

          <div className={classes.grow} />

          <div className={classes.categories}>
          <NavItem text="ÜBER UNS" to="1" variant="h6" scrollTo={(to: any) => props.scrollTo(to)}/>
          <NavItem text="NEWS" to="3" variant="h6" scrollTo={(to: any) => props.scrollTo(to)}/>
          <NavItem text="FOOD & DRINKS" to="5" variant="h6" scrollTo={(to: any) => props.scrollTo(to)}/>
          <NavItem text="RESERVIERUNG" to="7" variant="h6" scrollTo={(to: any) => props.scrollTo(to)}/>
          <NavItem text="Impressionen" to="9" variant="h6" scrollTo={(to: any) => props.scrollTo(to)}/>
          <NavItem text="PARTNER" to="11" variant="h6" scrollTo={(to: any) => props.scrollTo(to)}/>
          <NavItem text="INFOS" to="13" variant="h6" scrollTo={(to: any) => props.scrollTo(to)}/>
          <NavItem text="KONTAKT" to="15" variant="h6" scrollTo={(to: any) => props.scrollTo(to)}/>
          <NavItem text="Impressum" to="17" variant="h6" scrollTo={(to: any) => props.scrollTo(to)}/>
          <div className={classes.social}>
          <a href="https://instagram.com/stadtstrand_hagen" target="_blank" rel="noopener noreferrer"><Instagram /></a>
          <a href="https://facebook.com/stadtstrand.hagen" target="_blank" rel="noopener noreferrer"><Facebook /></a> 
          </div>
          </div>
          <div className={classes.sectionMobile}>
          <a href="https://instagram.com/stadtstrand_hagen" target="_blank" rel="noopener noreferrer"><Instagram /></a>
          <a href="https://facebook.com/stadtstrand.hagen" target="_blank" rel="noopener noreferrer"><Facebook /></a> 
          </div>
        </Toolbar>

        <div style={{backgroundColor: '#fed690', height:'10px'}}></div>
      </AppBar>


    </div>
  );
}


export default Navbar