import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import './Kontakt.scss'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import LinearProgress from '@material-ui/core/LinearProgress';
import axios from 'axios'

const statusState = {
  loading: false,
  sent: false,
  error: ''
}

const Kontakt: React.FC = () => {

  const [status, setStatus] = useState(statusState)

  const messageSendHandler = (formData: any) => {
    setStatus({ loading: true, sent: false, error: '' })
    
    axios.post('https://stadtstrand-hagen.de/services/public/api/v1/contact', formData)
    .then(response => {
        setStatus({ loading: false, sent: true, error: '' })

        if(response.data.status === 0){
            setStatus({ loading: false, sent: false, error: 'Das war nicht die richtige Hausnummer, schau unter Infos.' })
        }

    })
    .catch(error => {
      
        setStatus({ loading: false, sent: false, error: error.message })
    })
    
}

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
      captcha: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().max(30, 'Must be 30 characters or less').required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      message: Yup.string().min(20, 'Must be 20 characters or more.').required('Required'),
      captcha: Yup.string().min(2, 'Unsere zweistellige Hausnummer findest du unter Infos.').required('Required'),
    }),
    onSubmit: (values) => {
      messageSendHandler(values)
    },
  })

  return (
    <div>
      <h1>Kontakt</h1>
      <h3>Ihr könnt uns gerne schreiben</h3>
      <p>Dirket an "info [ @ ] stadtstrand-hagen.de" oder benutzt das Kontaktformular.</p>

      <Paper style={{ padding: '3rem' }}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            variant="outlined"
            className="TextField"
          />
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            variant="outlined"
            className="TextField"
          />

          <TextField
            fullWidth
            id="message"
            name="message"
            label="Nachricht"
            value={formik.values.message}
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            variant="outlined"
            className="TextField"
            multiline
            rows={4}
          />
          <TextField
            fullWidth
            id="captcha"
            name="captcha"
            label="Captcha"
            value={formik.values.captcha}
            onChange={formik.handleChange}
            error={formik.touched.captcha && Boolean(formik.errors.captcha)}
            helperText={formik.touched.captcha && formik.errors.captcha}
            variant="outlined"
            className="TextField"
            placeholder="Wie lautet unsere Hausnummer ?"
          />
          <br />
          <br />
          {status.loading ? <LinearProgress /> : <Button color="primary" variant="contained" fullWidth type="submit" className="TextField" >
            senden
          </Button> }

        </form>
        <br />
        <br />
        {status.sent ? 'Deine Nachricht wurde an uns verschickt. Danke' : ''}
        {status.error ? status.error : ''}
      </Paper>
    </div>
  )
}

export default Kontakt
